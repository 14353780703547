<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <h3>Add / Update Party</h3>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col dense>
        <v-form ref="externalPartyForm" v-model="valid" lazy-validation>
          <v-row dense>
            <v-col dense>
              <v-combobox v-model="externalParty.party_name" :rules="[rules.required]" @input="(v) => {
                  let party_info = v.toUpperCase().split('|');
                  externalParty.party_name = party_info[0];
                  externalParty.party_id = party_info[party_info.length - 1];
                  partyList.searchPartyName = externalParty.party_name;
                }
                " :label="'Party Name' + (externalParty.party_id ? ' (ID: ' + externalParty.party_id + ')' : '')"
                :items="partyList.party_name" :search-input.sync="partyList.searchPartyName"
                :hide-no-data="!partyList.searchPartyName" @change="partyNameChanged" hide-selected persistent-hint single
                required>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          partyList.searchPartyName
                        }}</strong>". Press <kbd>enter</kbd> to create a new party
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>              
              <v-text-field v-model="externalParty.party_city" :rules="[rules.required]" label="City" @input="(v) => {
                  externalParty.party_city = v.toUpperCase();
                }
                " required></v-text-field>
              <v-text-field v-model="externalParty.party_address" :rules="[rules.required]" label="Address" @input="(v) => {
                  externalParty.party_address = v.toUpperCase();
                }
                " required></v-text-field>
              <v-text-field v-model="externalParty.party_contact_name" :rules="[rules.required]" label="Contact Name"
                @input="(v) => {
                    externalParty.party_contact_name = v.toUpperCase();
                  }
                  " required></v-text-field>
              <v-text-field v-model="externalParty.party_contact_no" :rules="[rules.required]" label="Contact Number"
                type="number" required></v-text-field>
              <v-text-field v-model="externalParty.party_whatsapp_no" label="Whatsapp Number" type="number" required>
              </v-text-field>
              <v-text-field v-model="externalParty.party_email" :rules="[rules.validEmail]" label="E-mail">
              </v-text-field>
              <v-autocomplete v-model="externalParty.party_type" :items="['Customer', 'Supplier', 'Courier']" label="Type"
                required></v-autocomplete>
              <v-text-field v-model="externalParty.party_gstin" :rules="[rules.required]" @input="(v) => {
                  externalParty.party_gstin = v.toUpperCase();
                }
                " label="GSTIN"></v-text-field>
              <v-combobox v-model="externalParty.route_name" :rules="[rules.required]" @input="(v) => {
                  externalParty.route_name = v
                    .toUpperCase()
                    .split('|')[0];
                }
                " label="Collection Route" :items="collectionRouteList.route_name" :search-input.sync="collectionRouteList.searchCollectionRouteName
    " :hide-no-data="!collectionRouteList.searchCollectionRouteName" @change="collectionRouteChanged" hide-selected
                persistent-hint single required>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{
                          collectionRouteList.searchCollectionRouteName
                        }}</strong>". Press <kbd>enter</kbd> to create a collection route
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <v-text-field v-model="externalParty.remark" label="Remark"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col offset="8" cols="4" md="4" dense>
              <v-btn v-if="!updateMode && !createPartySuccess" :disabled="!valid || submitDisabled" color="success" @click="createTheParty">
                Create Party
              </v-btn>
              <v-btn v-if="updateMode" :disabled="!valid || submitDisabled" color="success" @click="updateTheParty">
                Update Party
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row class="text-center" dense>
      <v-col class="text-center" cols="10" dense>
        <alert-component :alert="alert"></alert-component>
      </v-col>
      <v-col class="text-center" cols="2" dense>
        <br/>
        <v-btn v-if="createPartySuccess" @click="newPartyForm()">Okay</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import {
  createExternalParty,
  externalParties,
  externalPartyRoutes,
  fetchExternalParty,
  updatedExternalParty
} from "../../services/apiCall.js";

import AlertComponent from "../common/AlertComponent.vue";

export default Vue.extend({
  components: { AlertComponent },
  name: "AddPartyComponent",

  mounted() {
    this.init();
  },

  data: () => ({
    externalParty: {
      party_name: "",
      party_city: "",
      party_address: "",
      party_contact_name: "",
      party_contact_no: "",
      party_whatsapp_no: "",
      party_email: "",
      party_type: "Customer",
      party_gstin: "NA",
      remark: "",
      route_name: "NA",
      route_id: -1,
    },
    partyList: {
      party_name: [],
      searchPartyName: "",
    },
    collectionRouteList: {
      route_name: [],
      searchCollectionRouteName: "",
    },
    submitDisabled: false,
    createPartySuccess: false,
    valid: false,
    rules: {
      required: (v) => !!v || "Required",
      validEmail: (v) => /.+@.+/.test(v) || "E-mail must be valid",
    },
    alert: {
      error: false,
      success: false,
      message: "",
    },
    externalPartyList: [],
    externalPartyRoutes: [],
    updateMode: false,
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    init() {
      this.externalParty = {
        party_name: "",
        party_city: "",
        party_address: "",
        party_contact_name: "",
        party_contact_no: "",
        party_whatsapp_no: "",
        party_email: "",
        party_type: "Customer",
        party_gstin: "NA",
        remark: "",
        route_name: "NA",
        route_id: -1,
      };

      externalParties()
        .then((res) => {
          console.log("externalParties", res);

          if (res.data.status === 0) {
            this.externalPartyList = res.data.result.external_party_list;
            this.partyList.party_name = this.externalPartyList.map(
              (x) =>
                x.party_name.toUpperCase() +
                "|" +
                x.party_city.toUpperCase() +
                "|" +
                x.party_address.toUpperCase() +
                "|" +
                x.id
            );
          } else {
            console.log("externalParties - err1", res);
          }
        })
        .catch((err) => {
          console.log("externalParties - err2", err);
        });

      externalPartyRoutes()
        .then((res) => {
          console.log("externalPartyRoutes", res);

          if (res.data.status === 0) {
            this.externalPartyRoutes = res.data.result.route_list;

            this.collectionRouteList.route_name = this.externalPartyRoutes.map(
              (x) => {
                return x.route_name.toUpperCase();
              }
            );
          } else {
            console.log("externalPartyRoutes - err1", res);
          }
        })
        .catch((err) => {
          console.log("externalPartyRoutes - err2", err);
        });
    },
    updateTheParty() {
      console.log("updateTheParty");

      this.submitDisabled = true;

      this.alert.success = this.alert.error = false;
      this.alert.message = "";

      try {
        this.externalParty.route_id = this.externalPartyRoutes.filter((x) => x.route_name === this.externalParty.route_name)[0].id;

        console.log("updateTheParty", this.externalParty);

        this.externalParty.party_name = this.externalParty.party_name.toUpperCase();

        updatedExternalParty(this.externalParty).then((res) => {
          console.log("updatedExternalParty", res);
          if (res.data.status == 0) {
            this.alert.success = true;
            this.alert.message = "External Patry Updated";
          } else {
            console.log("updatedExternalParty - err", res);
            this.alert.error = true;
            this.alert.message =
              "Error updating external party: " +
              res.data.error.error_message;
            this.submitDisabled = false;
          }
        }).catch((err) => {
          console.log("updateExternalParty - err", err);
          this.alert.error = true;
          this.alert.message = err;
          this.submitDisabled = false;
        });
      } catch (err) {
        console.log("updateExternalParty - err", err);
        this.alert.error = true;
        this.alert.message = err;
        this.submitDisabled = false;
      }
    },
    newPartyForm() {
      this.createPartySuccess = false;

      // initialise externalParty with default values
      this.externalParty.party_name = "";
      this.externalParty.party_city = "";
      this.externalParty.party_address = "";
      this.externalParty.party_contact_name = "";
      this.externalParty.party_contact_no = "";
      this.externalParty.party_whatsapp_no = "";
      this.externalParty.party_email = "";
      this.externalParty.party_type = "Customer";
      this.externalParty.party_gstin = "NA";
      this.externalParty.remark = "";
      this.externalParty.route_name = "NA";
      this.externalParty.route_id = -1; 

      this.alert.success = false;
      this.alert.error = false;
      this.alert.message = "";
    },
    createTheParty() {
      console.log("createTheParty");
      this.submitDisabled = true;

      this.alert.success = this.alert.error = false;
      this.alert.message = "";

      try {
        this.externalParty.route_id = this.externalPartyRoutes.filter((x) => x.route_name === this.externalParty.route_name)[0].id;

        console.log("createTheParty", this.externalParty);

        this.externalParty.party_name = this.externalParty.party_name.toUpperCase();

        createExternalParty(this.externalParty)
          .then((res) => {
            console.log("createExternalParty", res);
            if (res.data.status === 0) {
              this.alert.success = true;
              this.alert.message = "External Patry Added";
              this.createPartySuccess = true;
            } else {
              console.log("createExternalParty - err", res);
              this.alert.error = true;
              this.alert.message =
                "Error creating external party: " +
                res.data.error.error_message;
            }
            this.submitDisabled = false;
          })
          .catch((err) => {
            console.log("createExternalParty - err", err);
            this.alert.error = true;
            this.alert.message = err;
            this.submitDisabled = false;
          });
      } catch (err) {
        console.log("createExternalParty - err", err);
        this.alert.error = true;
        this.alert.message = err;
        this.submitDisabled = false;
      }
    },
    populatePartyInfoForUpdate(partyInfo) {
      console.log("populatePartyInfoForUpdate", partyInfo);

      try {
        fetchExternalParty({ party_id: partyInfo.id }).then((res) => {
          console.log("fetchExternalParty", res);
          if (res.data.status === 0) {
            let partyInfo = res.data.result.party_info;

            this.externalParty.party_id = partyInfo.id;
            this.externalParty.party_name = partyInfo.party_name;
            this.externalParty.party_address = partyInfo.party_address;
            this.externalParty.party_city = partyInfo.party_city;
            this.externalParty.party_contact_name = partyInfo.party_contact_name;
            this.externalParty.party_contact_no = partyInfo.party_contact_no;
            this.externalParty.party_email = partyInfo.party_email;
            this.externalParty.party_contact_no = partyInfo.party_contact_no;
            this.externalParty.party_gstin = partyInfo.party_gstin;
            this.externalParty.remark = partyInfo.remark;
            this.externalParty.route_id = partyInfo.route_id;

            // put this in update mode
            this.updateMode = true;
          } else {
            console.log("fetchExternalParty - err", res);
            this.alert.error = true;
            this.alert.message =
              "Error fetching external party: " +
              res.data.error.error_message;
          }
          this.submitDisabled = false;
        }).catch((err) => {
          console.log("populatePartyInfoForUpdate - err", err);
          this.alert.error = true;
          this.alert.message = err;
          this.submitDisabled = false;
        })
      } catch (err) {
        console.log("populatePartyInfoForUpdate - err", err);
        this.alert.error = true;
        this.alert.message = err;
        this.submitDisabled = false;
      }

    },
    partyNameChanged() {
      console.log("partyNameChanged", this.externalParty.party_name);

      // first check if the party name exisits
      let existingParty = this.externalParty.party_name.split("|");
      let selectedParty = null;

      if (existingParty.length === 1) {
        let party_name = existingParty[0];
        selectedParty = this.externalPartyList.filter((party) => party.party_name == party_name);
        if (selectedParty.length != 0) {
          this.populatePartyInfoForUpdate(selectedParty[0]);
        } else {
          // party name not found, add to list
          // if no create new
          this.newParty(this.externalParty.party_name.toUpperCase());
        }
      } else if (existingParty.length == 4) {
        let party_id = existingParty[3];
        selectedParty = this.externalPartyList.filter((party) => party.id == party_id);
        if (selectedParty.length != 0) {
          this.populatePartyInfoForUpdate(selectedParty[0]);
        } else {
          // party name not found, add to list
          // if no create new
          this.newParty(this.externalParty.party_name.toUpperCase());
        }
      } else {
        // party name not found, add to list
        // if no create new
        this.newParty(this.externalParty.party_name.toUpperCase());
      }
    },
    newParty(partyName) {
      this.partyList.party_name.push(partyName);

      this.externalParty.party_id = "";
      this.externalParty.party_address = "";
      this.externalParty.party_city = "";
      this.externalParty.party_contact_name = "";
      this.externalParty.party_email = "";
      this.externalParty.party_contact_no = "";
      this.externalParty.party_gstin = "NA";
      this.externalParty.remark = "";
      this.externalParty.route_name = "NA";
      this.externalParty.route_id = -1;
      this.externalParty.party_type = "Customer";

      this.updateMode = false;
    },
    collectionRouteChanged() {
      console.log(
        "collectionRouteChanged",
        this.externalParty.route_name
      );
      this.collectionRouteList.route_name.push(
        this.externalParty.route_name.toUpperCase()
      );
    },
  },
});
</script>