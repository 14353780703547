/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import html2pdf from 'html2pdf.js';

/** print utilities utilities */

export function printPage(pageName, pageElement) {
    console.log('printPage', pageName, pageElement);

    new Promise((resolve, reject) => {
        try {
            let pdfFileName = pageName + '.pdf';
            let opt = {
                margin: 10,
                filename: pdfFileName,
                image: { type: 'jpeg', quality: 0.98 },
            };
            html2pdf().set(opt).from(pageElement).save();

            // c.f. https://github.com/eKoopmans/html2pdf.js/issues/243
            html2pdf().set(opt).from(pageElement).toPdf().get('pdf').then(function (pdfObj) {
                pdfObj.autoPrint();
                let pdfURL = pdfObj.output('bloburl');
                window.open(pdfURL, '_blank');
            });

            resolve(true);
        } catch (ignored) {
            console.log('printPage - err', ignored);
            reject(false);
        }
    });
}

export function sendToPrinter(pdfFileName) {
    let billWindow = open("", "_billPrint");
    console.log("billWindow", billWindow);
    billWindow.document.body.innerHTML = '<html><body><embed src="' + pdfFileName + '"/></body></html>';
    billWindow.print();
}

