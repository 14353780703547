<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center" style="line-height: 1">
      <v-col cols="12">
        <div id="billPreview">
          <table width="100%" style="border: 0px; border-spacing: 0px">
            <colgroup>
              <col span="1" style="width: 10%" />
              <col span="8" style="width: 80%" />
              <col span="1" style="width: 10%" />
            </colgroup>

            <bill-header-component></bill-header-component>

            <!-- customer contact, Bill number -->
            <tbody>
              <tr>
                <td style="
                    border: 1px solid;
                    border-right: 0px;
                    margin-right: 0px;
                    text-align: left;
                    padding-left: 10px;
                  " colspan="6">
                  Bill No: <b>{{ orderID }}</b><br />
                  Name: <b>{{ billingCustomer.party_name }}</b><br />
                  Address: {{ billingCustomer.party_city }},
                  {{ billingCustomer.party_address }}
                </td>
                <td style="
                    border: 1px solid;
                    border-left: 0px;
                    margin-left: 0px;
                    text-align: left;
                  " colspan="4">
                  Date: <b>{{ billExtras.billDate.toDateString() }}</b> <br />
                  GSTIN: {{ billingCustomer.party_gstin }}
                </td>
              </tr>
              <!-- item header -->
              <tr>
                <td style="
                    border: 1px solid;
                    border: 0px;
                    text-align: left;
                    padding-left: 0px;
                  " colspan="10">
                  <table width="100%" style="border: 0px; border-spacing: 0px">
                    <colgroup>
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 50%" />
                      <col span="1" style="width: 10%" />
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 5%" />
                      <col span="1" style="width: 5%" />
                    </colgroup>
                    <tr>
                      <td style="
                          border: 1px solid;
                          border-top: 0px;
                          margin-right: 0px;
                          text-align: center;
                        ">
                        <b>No.</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-left: 0px;
                          text-align: center;
                        ">
                        <b>Item Description</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>Remark</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-left: 0px;
                          text-align: center;
                        ">
                        <b>Qty</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>Gross</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>Discount (%)</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>Taxable Value</b>
                      </td>
                      <td v-if="!billSettings.sameStateCustomer" colspan="2" style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>IGST</b>
                      </td>
                      <td v-if="billSettings.sameStateCustomer" style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>CGST</b>
                      </td>
                      <td v-if="billSettings.sameStateCustomer" style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        <b>SGST</b>
                      </td>
                      <td style="
                          border: 1px solid;
                          border-left: 0px;
                          border-top: 0px;
                          margin-left: 0px;
                          text-align: center;
                        ">
                        <b>Total</b>
                      </td>
                    </tr>
                    <!-- item list -->
                    <tr v-for="(item, index) in cart" v-bind:key="item.id">
                      <td style="
                          border-left: 1px solid;
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: center;
                        ">
                        {{ index + 1 }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: left;
                          padding-left: 5px;
                        ">
                        <span style="margin: 0px; padding: 0px; line-height: 0.5">
                          <small style="font-size: 11px">{{
    item.item_name
  }}</small>
                          <br />
                          <small style="font-size: 8px">HSN: {{ item.hsn_code }}, &nbsp;</small>
                          <small style="font-size: 8px">TAX: {{ getDisplayTaxRate(item) }}</small>
                        </span>
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ item.remark }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ item.quantity }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ item.price.toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ parseFloat(item.discount).toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ taxableItemTotal(item).toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " colspan="2" v-if="!billSettings.sameStateCustomer">
                        {{ igstItemTax(item).toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " v-if="billSettings.sameStateCustomer">
                        {{ cgstItemTax(item).toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          border-bottom: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " v-if="billSettings.sameStateCustomer">
                        {{ sgstItemTax(item).toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          border-bottom: 1px solid;
                          padding-right: 5px;
                          padding-right: 5px;
                        ">
                        {{ itemTotalWithTax(item).toFixed(2) }}
                      </td>
                    </tr>
                    <!-- total section -->
                    <tr>
                      <td style="
                          border-left: 1px solid;
                          border-right: 1px solid;
                          margin-right: 2px;
                          padding-left: 5px;
                          text-align: left;
                          text-align: right;
                          padding-right: 5px;
                        " colspan="2">
                        <h4>Total</h4>
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        "></td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ totalQty() }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ totalPrice().toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ totalDiscount().toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        {{ billTotal().toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " colspan="2" v-if="!billSettings.sameStateCustomer">
                        {{ igstTaxTotal().toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " v-if="billSettings.sameStateCustomer">
                        {{ cgstTaxTotal().toFixed(2) }}
                      </td>
                      <td style="
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " v-if="billSettings.sameStateCustomer">
                        {{ sgstTaxTotal().toFixed(2) }}
                      </td>
                      <td style="border-right: 1px solid; margin-right: 0px"></td>
                    </tr>
                    <!-- grand total -->
                    <tr>
                      <td style="
                          border-top: 1px solid;
                          border-left: 1px solid;
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        " colspan="9">
                        <b>Grand Total</b>
                      </td>
                      <td style="
                          border-top: 1px solid;
                          border-right: 1px solid;
                          margin-right: 2px;
                          text-align: right;
                          padding-right: 5px;
                        ">
                        <h3>&#8377;{{ grandTotal().toFixed(2) }}</h3>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <!-- footer section -->
              <bill-footer-component></bill-footer-component>

              <tr v-if="billExtras.amountReceived != 0">
                <td style="
                    border: 0px;
                    margin-top: 2px;
                    margin-left: 2px;
                    text-align: left;
                    padding-top: 5px;
                  " colspan="10">
                  <b>Amount Received:</b> {{ billExtras.amountReceived }}
                </td>
              </tr>
              <tr v-if="billExtras.remark != ''">
                <td style="
                    border: 0px;
                    margin-top: 2px;
                    margin-left: 2px;
                    text-align: left;
                    padding-top: 5px;
                  " colspan="10">
                  <b>Remark:</b> {{ billExtras.remark }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!printOnly">
      <v-col>
        <v-text-field v-model="billExtras.amountReceived" :rules="[rules.required]" label="Amount Received"
          type="number" required></v-text-field>
        <v-text-field v-model="billExtras.remark" label="Remark" type="text" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" style="text-align: left;">
        <v-btn v-if="!printOnly" raised @click="goBackToCart" rounded slot="end">
          Go Back
        </v-btn>
      </v-col>
      <v-col cols="10" style="text-align: right;">
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
        <v-btn v-if="!printOnly" raised color="success" @click="confirmAndPrint" rounded>
          Confirm and Print
        </v-btn>
        <v-btn v-if="printOnly" raised color="success" @click="reprint" rounded>
          Re-Print
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import {
  createSalesOrder,
  updateSalesOrder,
  createGSTOrder,
  updateGSTOrder,
} from "../../services/apiCall.js";
import { printPage } from "../../services/print.js";
import BillHeaderComponent from "./BillHeaderComponent.vue";
import BillFooterComponent from "./BillFooterComponent.vue";

export default Vue.extend({
  components: { BillHeaderComponent, BillFooterComponent },
  name: "BillPreviewComponent",

  props: [
    "postBillCallback",
    "callbackObject",
    "billSettings",
    "orderID",
    "printOnly",
    "billDate",
    "billDetails"
  ],

  component: {
    BillHeaderComponent,
    BillFooterComponent,
  },

  data: () => ({
    snack: false,
    snackColor: "",
    snackText: "",
    billExtras: {
      amountReceived: 0,
      remark: "",
      billDate: new Date(),
    },
    rules: {
      required: (v) => !!v || "Required",
    },
  }),

  watch: {
    billDate: function (newval, oldval) {
      console.log("watching - billDate", oldval, newval);

      if (oldval != newval) {
        if (newval) {
          this.billExtras.billDate = new Date(newval);
        }
      }
    },
    billDetails: function (newval, oldval) {
      console.log("watching - billDetails", oldval, newval);

      if (oldval != newval) {
        if (newval) {
          this.billExtras.amountReceived = this.billDetails.amount_received;
          this.billExtras.remark = this.billDetails.remark;
        }
      }
    }
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    cart() {
      return this.$store.getters.cart;
    },
    billingCustomer() {
      return this.$store.getters.billingCustomer;
    },
  },

  methods: {
    async reprint() {
      console.log("reprint");

      let pres = await printPage(
        this.orderID,
        document.querySelector("#billPreview")
      );
      console.log("[print-bill]", pres);
    },
    confirmAndPrint() {
      console.log("confirmAndPrint");

      // TODO:
      let order = {
        party_id: this.$store.getters.billingCustomer.id,
        total_value: this.grandTotal(),
        total_tax: this.taxTotal(),
        total_discount: this.totalDiscount(),
        other_charges: {},
        remark: this.billExtras.remark,
        amount_received: this.billExtras.amountReceived,
      };
      console.log("confirmAndPrint - order", order);

      if (this.getBillType() == "GST Bill") {
        let savedSalesOrder = this.$store.getters.salesOrder;
        console.log("salesSavesOrder", savedSalesOrder, typeof savedSalesOrder);
        if (Object.keys(savedSalesOrder).length == 0) {
          order["sales_order_id"] = -1;
        } else {
          order["sales_order_id"] = savedSalesOrder.id;
        }

        console.log("confirmAndPrint - gst order", order);

        this.makeGSTOrder(order);
      } else {
        this.makeSalesOrder(order);
      }
    },
    makeSalesOrder(order) {
      createSalesOrder(order)
        .then((res) => {
          console.log("createSalesOrder", res);

          if (res.data.status === 0) {
            order.order_id = res.data.result.new_order.id;
            order.actual_order_id = res.data.result.new_order.order_id;
            this.orderID = order.actual_order_id;
            this.billExtras.billDate = res.data.result.new_order.createdAt;
            order.order_items = this.$store.getters.cart.map((x) => {
              x["total_value"] = this.itemTotal(x);
              x["total_tax"] = this.itemTax(x);
              x["total_discount"] = this.percentRate(x.price * x.quantity, x.discount);
              x["tax_breakup"] = {
                rate: x.tax_rate,
                breakup: this.itemTaxBreakup(x),
              };

              return x;
            });

            console.log("order_items", order.order_items);

            updateSalesOrder(order)
              .then(async (ures) => {
                if (ures.data.status === 0) {
                  if (this.postBillCallback) {
                    let pres = await printPage(
                      order.actual_order_id,
                      document.querySelector("#billPreview")
                    );
                    console.log("[print-bill]", pres);
                    this.postBillCallback(this.callbackObject);
                  }
                } else {
                  this.snack = true;
                  this.snackText =
                    "Unable to update order at this time. Try again. Order ID: " +
                    order.order_id;
                }
              })
              .catch((uerr) => {
                console.log("updateSalesOrder - err", uerr);
              });
          } else {
            this.snack = true;
            this.snackText = "Unable to create order at this time. Try again.";
          }
        })
        .catch((err) => {
          console.log("createSalesOrder - err", err);
        });
    },
    makeGSTOrder(order) {
      createGSTOrder(order)
        .then((res) => {
          console.log("createGSTOrder", res);

          if (res.data.status === 0) {
            order.order_id = res.data.result.new_order.id;
            order.actual_order_id = res.data.result.new_order.order_id;
            this.orderID = order.actual_order_id;
            order.order_items = this.$store.getters.cart.map((x) => {
              x["total_value"] = this.itemTotal(x);
              x["total_tax"] = this.itemTax(x);
              x["total_discount"] = this.percentRate(x.price * x.quantity, x.discount);
              x["tax_breakup"] = {
                rate: x.tax_rate,
                breakup: this.itemTaxBreakup(x),
              };

              return x;
            });

            console.log("order_items", order.order_items);

            updateGSTOrder(order)
              .then(async (ures) => {
                if (ures.data.status === 0) {
                  if (this.postBillCallback) {
                    let pres = await printPage(
                      order.actual_order_id,
                      document.querySelector("#billPreview")
                    );
                    console.log("[print-bill]", pres);
                    this.postBillCallback(this.callbackObject);
                  }
                } else {
                  this.snack = true;
                  this.snackText =
                    "Unable to update order at this time. Try again. Order ID: " +
                    order.order_id;
                }
              })
              .catch((uerr) => {
                console.log("updateGSTOrder - err", uerr);
              });
          } else {
            this.snack = true;
            this.snackText = "Unable to create order at this time. Try again.";
          }
        })
        .catch((err) => {
          console.log("createGSTOrder - err", err);
        });
    },
    goBackToCart() {
      console.log("goBackToCart");
      this.postBillCallback(this.callbackObject, false);
    },
    getDisplayTaxRate(item) {
      console.log("getDisplayTaxRate", item.tax_rate);

      if (this.billSettings.sameStateCustomer) {
        return (
          "CGST: " +
          item.tax_rate.cgst +
          "%, SGST: " +
          item.tax_rate.sgst +
          "%, Cess: " +
          item.tax_rate.cess +
          "%"
        );
      } else {
        return "IGST: " + item.tax_rate.igst + "%";
      }
    },
    getBillType() {
      return this.billSettings.salesBillOrderType;
    },
    billTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map(
          (x) => x.price * x.quantity - this.percentRate(x.price * x.quantity, x.discount)
        )
        .reduce((a, b) => a + b);
    },
    grandTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.itemTotalWithTax(x))
        .reduce((a, b) => a + b);
    },
    taxTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.itemTax(x))
        .reduce((a, b) => a + b);
    },
    cgstTaxTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.cgstItemTax(x))
        .reduce((a, b) => a + b);
    },
    sgstTaxTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.sgstItemTax(x))
        .reduce((a, b) => a + b);
    },
    igstTaxTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.igstItemTax(x))
        .reduce((a, b) => a + b);
    },
    cessTaxTotal() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.cessItemTax(x))
        .reduce((a, b) => a + b);
    },
    totalQty() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => parseFloat(x.quantity))
        .reduce((a, b) => a + b);
    },
    totalPrice() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => x.price)
        .reduce((a, b) => a + b);
    },
    totalDiscount() {
      if (this.$store.getters.cart.length == 0) return 0;

      return this.$store.getters.cart
        .map((x) => this.percentRate(x.price * x.quantity, x.discount))
        .reduce((a, b) => a + b);
    },
    itemTotal(item) {
      return item.price * item.quantity;
    },
    taxableItemTotal(item) {
      return (
        item.price * item.quantity - this.percentRate(item.price * item.quantity, item.discount)
      );
    },
    percentRate(rate, percent) {
      return (rate * percent) / 100.0;
    },
    cgstItemTax(item) {
      var itmTot = this.taxableItemTotal(item);

      if (item.tax_rate.composite_supply) {
        let principle_item_taxrate = this.getPrincipleItemTax();
        item.tax_rate.cgst = principle_item_taxrate.cgst;
      }

      return this.percentRate(itmTot, item.tax_rate.cgst);
    },
    sgstItemTax(item) {
      var itmTot = this.taxableItemTotal(item);

      if (item.tax_rate.composite_supply) {
        let principle_item_taxrate = this.getPrincipleItemTax();
        item.tax_rate.sgst = principle_item_taxrate.sgst;
      }

      return this.percentRate(itmTot, item.tax_rate.sgst);
    },
    igstItemTax(item) {
      var itmTot = this.taxableItemTotal(item);

      if (item.tax_rate.composite_supply) {
        let principle_item_taxrate = this.getPrincipleItemTax();
        item.tax_rate.igst = principle_item_taxrate.igst;
      }

      return this.percentRate(itmTot, item.tax_rate.igst);
    },
    cessItemTax(item) {
      item;
      // TODO: to enable cess make take a different approach, shoud be taken from global_tax_settings
      // var itmTot = this.taxableItemTotal(item);
      // return this.percentRate(itmTot, item.tax_rate.cess);
      return 0.0;
    },
    itemTax(item) {
      if (this.billSettings.sameStateCustomer) {
        return (
          this.cgstItemTax(item) +
          this.sgstItemTax(item) +
          this.cessItemTax(item)
        );
      } else {
        return this.igstItemTax(item) + this.cessItemTax(item);
      }
    },
    itemTaxBreakup(item) {
      if (this.billSettings.sameStateCustomer) {
        return {
          cgst: this.cgstItemTax(item),
          sgst: this.sgstItemTax(item),
          cess: this.cessItemTax(item),
          hsn_code: item.hsn_code,
        };
      } else {
        return {
          igst: this.igstItemTax(item),
          cess: this.cessItemTax(item),
          hsn_code: item.hsn_code,
        };
      }
    },
    itemTotalWithTax(item) {
      return this.taxableItemTotal(item) + this.itemTax(item);
    },
    getPrincipleItemTax() {
      // principle item is that item that has got the higher tax rate
      // it is used to calulate the tax rate of the item whose tax rate is set as "composite_supply"

      // iterate over all items, whose tax rate is not marked "cmposite_supply"
      // find the item with the highest tax rate

      let highest_rate = 0;
      let tax_breakup = {};
      for (var i = 0; i < this.$store.getters.cart.length; i++) {
        var item = this.$store.getters.cart[i];
        if (!item.tax_rate.composite_supply) {
          if (item.tax_rate.igst > highest_rate) {
            tax_breakup = item.tax_rate;
          }
        }
      }

      return tax_breakup;
    },
  },
});
</script>