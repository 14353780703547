<template>
  <span v-if="userLoggedIn">
    <v-row v-if="alert.error || alert.success" class="no-padding" dense>
      <v-col cols="12" class="no-padding">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>

    <!-- bill configurations -->

    <!-- party name search -->
    <v-row class="text-center no-padding" v-if="!billSettings.billPreviewEnabled" dense>
      <v-col cols="8" class="no-padding text-left" dense>
        <v-autocomplete :items="partyNameList" v-model="bill.customer" :rules="[rules.required]" label="Customer"
          @change="partyChanged" required class="no-padding">
        </v-autocomplete>

        <span class="text-left no-padding" v-if="bill.customer != ''">
          <small>
            Opening Balance:
            <b>{{ bill.party_diary.current_value.toFixed(2) }}</b>; Last Received:
            <b>{{ bill.party_diary.entry_value.toFixed(2) }} ({{
    bill.party_diary.payment_mode
  }}, {{ bill.party_diary.entry_type }})</b>
            On: <b>{{ new Date(bill.party_diary.createdAt).toDateString() }}</b>
          </small>
        </span>
      </v-col>
      <v-col cols="2" dense>
        <v-switch v-model="billSettings.counterRateType" inset :label="getRateType()">
        </v-switch>
      </v-col>
      <v-col cols="2" dense>
        <v-switch v-model="billSettings.sameStateCustomer" inset :label="getCustomerBillingState()">
        </v-switch>
      </v-col>

      <!-- item search to add to cart-->
      <v-col cols="4" class="no-padding" v-if="!billSettings.billPreviewEnabled" dense>
        <item-search-and-add-component :isCounterRate="billSettings.counterRateType"></item-search-and-add-component>
      </v-col>

      <!-- cart component -->
      <v-col cols="8" class="no-padding" v-if="!billSettings.billPreviewEnabled" dense>
        <cart-component :generateBillCallback="generareBillPreview" :callbackObject="callbackObject"></cart-component>
      </v-col>
    </v-row>

    <!-- bill preview component -->
    <v-row v-if="billSettings.billPreviewEnabled" class="no-padding" dense>
      <v-col cols="12" class="no-padding" dense>
        <bill-preview-component :postBillCallback="billingCompletedCallback" :callbackObject="callbackObject"
          :billSettings="billSettings" :billDate="billDate"></bill-preview-component>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import Vue from "vue";

import ItemSearchAndAddComponent from "./ItemSearchAndAddComponent.vue";
import CartComponent from "./CartComponent.vue";
import BillPreviewComponent from "./BillPreviewComponent.vue";
import AlertComponent from "../common/AlertComponent";

import {
  externalParties,
  listSalesOrder,
  fetchSalesOrder,
} from "../../services/apiCall.js";

export default Vue.extend({
  name: "NewBillComponent",

  components: {
    ItemSearchAndAddComponent,
    CartComponent,
    BillPreviewComponent,
    AlertComponent,
  },

  mounted() {
    this.init();
  },

  data: () => ({
    salesOrderNumber: "",
    salesOrderNumberList: [],
    billSettings: {
      counterRateType: true,
      salesBillOrderType: "GST Bill", // default is GST bill order
      sameStateCustomer: true,
      billPreviewEnabled: false,
    },
    bill: {
      customer: "",
      party: {},
      party_diary: {},
    },
    billTypeOptions: ["Sales Order", "GST Bill"],
    rules: {
      required: (v) => !!v || "Required",
    },
    externalPartyList: [],
    partyNameList: [],
    externalPartyMap: {},
    callbackObject: this,
    alert: {
      error: false,
      success: false,
      message: "",
    },
    billDate: new Date(),
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    customers() {
      return this.$store.getters.customers;
    },
    cart() {
      return this.$store.getters.cart;
    },
  },

  methods: {
    init() {
      externalParties({ party_type: "Customer" })
        .then((res) => {
          console.log("externalParties", res);

          if (res.data.status === 0) {
            this.externalPartyList = res.data.result.external_party_list;
            this.partyNameList = this.externalPartyList.map((party) => {
              let partyCode =
                party.party_name.toUpperCase() +
                "|" +
                party.party_city.toUpperCase() +
                "|" +
                party.party_address.toUpperCase() +
                "|" +
                party.id;

              this.externalPartyMap[partyCode] = party;

              return partyCode;
            });
          } else {
            console.log("externalParties - err1", res);
          }
        })
        .catch((err) => {
          console.log("externalParties - err2", err);
        });
      listSalesOrder({})
        .then((res) => {
          console.log("listSalesOrder", res);

          if (res.data.status === 0) {
            this.salesOrderNumberList = res.data.result.order_list.map(
              (x) => x.order_id
            );
          } else {
            console.log("listSalesOrder - err1", res);
          }
        })
        .catch((err) => {
          console.log("listSalesOrder - err2", err);
        });
    },
    getBillType() {
      return this.billSettings.salesBillOrderType;
    },
    getRateType() {
      return this.billSettings.counterRateType
        ? "Counter Rate"
        : "Dispatch Rate";
    },
    getCustomerBillingState() {
      return this.billSettings.sameStateCustomer ? "CGST" : "IGST";
    },
    generareBillPreview(callbackObject = this) {
      console.log("generateBillPreview", callbackObject, callbackObject.bill);

      if (callbackObject.bill.customer.trim() === "") {
        this.alert.error = true;
        this.alert.success = false;
        this.alert.message = "No Customer Selected";
        return;
      }

      console.log("billingCustomer", callbackObject.bill);

      // get the selected party, get the party object and save it to billingCustomer
      var party = callbackObject.bill.customer.toUpperCase().split("|");

      console.log("billingCustomer", party);
      // console.log("billingCustomer", callbackObject.externalPartyList);
      // console.log("billingCustomer", callbackObject.externalPartyList[0]);

      var billCustomer = callbackObject.externalPartyList.filter((x) => {
        return (
          x.party_name == party[0] &&
          x.party_city == party[1] &&
          x.party_address == party[2] &&
          x.id == party[3]
        );
      })[0];

      console.log("billingCustomer", billCustomer);

      callbackObject.$store.dispatch("setBillingCustomer", billCustomer);

      callbackObject.billSettings.billPreviewEnabled = true;
    },
    billingCompletedCallback(callbackObject = this, purgeCart = true) {
      callbackObject.billSettings.billPreviewEnabled = false;

      if (purgeCart) {
        // clear and prepare for new billing
        callbackObject.bill.customer = "";
        callbackObject.bill.party = {};
        callbackObject.bill.party_diary = {};
        callbackObject.$store.dispatch("purgeCart");
      }
    },
    salesOrderChanged() {
      console.log("salesOrderChanged", this.salesOrderNumber);

      this.alert.error = false;
      this.alert.success = false;
      this.alert.message = "";

      // fetch details of the sales order
      fetchSalesOrder({ order_id: this.salesOrderNumber })
        .then((res) => {
          console.log("fetchSalesOrder", res);
          if (res.data.status === 0) {
            let salesOrder = res.data.result.sales_order;
            this.bill.customer =
              salesOrder.party.party_name +
              "|" +
              salesOrder.party.party_city +
              "|" +
              salesOrder.party.party_address +
              "|" +
              salesOrder.party.id;
            this.bill.party = salesOrder.party;
            this.bill.party_diary = salesOrder.party_diary;
            console.log("loaded-bill-customer", this.bill, salesOrder.items);

            let cartItems = salesOrder.items.map((item) => {
              var newItem = {
                id: item.item_id,
                item_name: item.item_name,
                price: item.total_value,
                quantity: item.quantity ? item.quantity : 1,
                discount: 0,
                tax_breakup: item.tax_breakup.breakup,
                tax_rate: item.tax_breakup.rate,
                hsn_code: item.tax_breakup.hsn_code,
              };
              return newItem;
            });
            console.log("loaded-bill-customer-cart", cartItems);
            this.$store.dispatch("setCart", cartItems);
            this.$store.dispatch("setSalesOrder", salesOrder);
            console.log("loaded-bill-new-cart", this.$store.getters.cart);
          } else {
            this.alert.error = true;
            this.alert.message =
              "Unable to load sales order. Error is: " +
              res.data.error.error_message;
          }
        })
        .catch((err) => {
          console.log("fetchSalesOrder - err", err);
          this.alert.error = true;
          this.alert.message =
            "Unable to load sales order. Error is: " + err.toString();
        });
    },
    partyChanged() {
      console.log(
        "partyChanged",
        this.bill.customer,
        this.externalPartyMap[this.bill.customer]
      );
      this.bill.party = this.externalPartyMap[this.bill.customer];
      this.bill.party_diary = this.externalPartyMap[this.bill.customer].diary;
      this.alert.error = false;
    },
  },
});
</script>