<template>
  <tr>
    <td
      style="
        border: 1px solid;
        border-bottom: 0px;
        width: 90%;
        padding-left: 10px;
        text-align: center;
      "
      colspan="9"
    >
      <b>SUDARSAN SCIENTIFIC COMPANY</b><br />
      <small><i>Importer, Manufacturers & Exporters of:</i></small
      ><br />
      BIFOCAL BLANKS & OPHTHALMIC LENSES<br />
      <small>Branch: G.S.S. Complex, Convent Road</small><br />
      <small>Ernakulam - 35, Kerala</small><br />
      <small>H.O. Kodungallur, Br. Calicut, Trivandrum</small>
    </td>
    <td
      style="
        border: 1px solid;
        border-left: 0px;
        border-bottom: 0px;
        width: 5%;
        padding-left: 5px;
        text-align: center;
      "
      colspan="1"
    >
      <small><b>GSTIN:</b> <br />32AAOFS4334H1Z6</small><br />
      <small><b>Phone:</b> <br />0484 2362460, 2362760</small><br />
      <small><b>Email:</b> <br />1986sudarsan@gmail.com</small>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "BillHeaderComponent",
});
</script>
