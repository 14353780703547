/* eslint-disable */

/** wrapper for all api calls */

import axios from "axios";

import { getUser } from "./db";

const BASE_URL = 'https://opticals-api.bisness.in';

const API_KEY = '69ba130436051f6c3a5ebd502b79f4d0';

async function getStdHeader() {
    // console.log('getStdHeader - user');

    return {
        "api_key": API_KEY,
    };
}

async function getHeader() {
    var currentUser = await getUser('user');

    // console.log('getHeader - user', currentUser);

    return {
        "api_key": API_KEY,
        "user_id": currentUser.user_id,
        "auth_token": currentUser.auth.token,
        "session_id": currentUser.auth.session
    };
}

export async function login(cred) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/user/login', cred, { headers: theHeaders });
}

export async function branches(options) {
    var theHeaders = await getStdHeader();
    return axios.post(BASE_URL + '/api/v1/branch/list', options, { headers: theHeaders });
}

export async function categories(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/category/list', options, { headers: theHeaders });
}

export async function items(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/list', options, { headers: theHeaders });
}

export async function companies(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/list-company', options, { headers: theHeaders });
}

export async function products(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/list-product', options, { headers: theHeaders });
}

export async function types(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/list-type', options, { headers: theHeaders });
}

export async function colours(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/list-colour', options, { headers: theHeaders });
}

export async function itemSpecificList(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/list-specific', options, { headers: theHeaders }); 
}

export async function itemSearch(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/search', options, { headers: theHeaders });
}

export async function createItem(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/create', options, { headers: theHeaders });
}

export async function updateItem(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/update', options, { headers: theHeaders });
}

export async function updateItemField(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/update-field', options, { headers: theHeaders });
}

export async function createExternalParty(options) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/external-party/create', options, { headers: theHeaders });
}

export async function externalParties(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/external-party/list', options, { headers: theHeaders });
}

export async function externalPartyRoutes(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/external-party/route/list', options, { headers: theHeaders });
}

export async function createSalesOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/sales-order/create', options, { headers: theHeaders });
}

export async function updatedExternalParty(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/external-party/update', options, { headers: theHeaders });
}

export async function fetchExternalParty(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/external-party/fetch', options, { headers: theHeaders });
}

export async function updateSalesOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/sales-order/update', options, { headers: theHeaders });
}

export async function listSalesOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/sales-order/list', options, { headers: theHeaders });
}

export async function fetchSalesOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/sales-order/fetch', options, { headers: theHeaders });
}

export async function createGSTOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/create', options, { headers: theHeaders });
}

export async function updateGSTOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/update', options, { headers: theHeaders });
}

export async function listGSTOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/list', options, { headers: theHeaders });
}

export async function listGSTOrderIDs(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/list-order-id', options, { headers: theHeaders });
}

export async function fetchGSTOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/fetch', options, { headers: theHeaders });
}

export async function cancelGSTOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/cancel', options, { headers: theHeaders });
}

export async function returnGSTOrder(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/gst-order/return', options, { headers: theHeaders });
}

export async function dailySalesReport(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/report/daily-sales', options, { headers: theHeaders });
}

export async function getCategoryItems(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/item/fetch-category-items', options, { headers: theHeaders });
}

export async function partyWiseSalesReport(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/report/party-wise-sales', options, { headers: theHeaders });
}

export async function fetchPartyLedger(options = {}) {
    var theHeaders = await getHeader();
    return axios.post(BASE_URL + '/api/v1/ledger/fetch', options, { headers: theHeaders });
}
