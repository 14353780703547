<template>
  <v-container v-if="userLoggedIn">
    <v-row v-if="alert.error || alert.success">
      <v-col cols="12">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
    <v-row class="text-center no-padding">
      <v-col cols="12"> <b>Daily Sales Report</b> </v-col>
      <v-col cols="4">
        <v-menu ref="reportDateMenu" v-model="reportDateMenu" :close-on-content-click="false"
          transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="reportDate" label="Report Date" persistent-hint :prepend-icon="icons.mdiCalendar"
              v-bind="attrs" v-on="on" readonly></v-text-field>
          </template>
          <v-date-picker v-model="reportDate" no-title></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" style="text-align: left;">
        <v-btn @click="fetchReport(reportDate)">Fetch Report</v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center no-padding">
      <v-col cols="12" class="no-padding">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td><b>Orders</b></td>
                <td><b>Returns</b></td>
                <td><b>Cash Flow</b></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">Count:</td>
                          <td class="text-right">
                            {{ salesSummary.orders.count }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Value:</td>
                          <td class="text-right">
                            {{ salesSummary.orders.value.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Quantity:</td>
                          <td class="text-right">
                            {{ salesSummary.orders.quantity }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Discounts:</td>
                          <td class="text-right">
                            {{ salesSummary.orders.discounts.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Taxes:</td>
                          <td class="text-right">
                            {{ salesSummary.orders.tax.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Parties:</td>
                          <td class="text-right">
                            {{ salesSummary.orders.parties }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
                <td class="text-center">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">Count:</td>
                          <td class="text-right">
                            {{ salesSummary.returns.count }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Value:</td>
                          <td class="text-right">
                            {{ salesSummary.returns.value.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Quantity:</td>
                          <td class="text-right">
                            {{ salesSummary.returns.quantity }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
                <td class="text-center">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="text-left">Received:</td>
                          <td class="text-right">
                            {{ salesSummary.cash_flow.received.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Outstanding (today):</td>
                          <td class="text-right">
                            {{ salesSummary.cash_flow.outstanding.today.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">Outstanding (aggregate):</td>
                          <td class="text-right">
                            {{ salesSummary.cash_flow.outstanding.aggregate.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <hr />

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Party</th>
                <th class="text-center">Address</th>
                <th colspan="2" class="text-center">Orders</th>
                <th colspan="2" class="text-center">Returns</th>
                <th class="text-center">Received</th>
                <th colspan="2" class="text-center">Outstanding</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th class="text-center">Value</th>
                <th class="text-center">Qty</th>
                <th class="text-center">Value</th>
                <th class="text-center">Qty</th>
                <th></th>
                <th class="text-center">Today</th>
                <th class="text-center">Aggregate</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="party in salesSummary.parties"
                v-bind:key="party.party_id"
              >
                <td class="text-left">{{ party.name }}</td>
                <td class="text-left">{{ party.address }}</td>
                <td class="text-right">{{ party.orders.value.toFixed(2) }}</td>
                <td class="text-right">{{ party.orders.quantity }}</td>
                <td class="text-right">{{ party.returns.value.toFixed(2) }}</td>
                <td class="text-right">{{ party.returns.quantity }}</td>
                <td class="text-right">{{ party.received.toFixed(2) }}</td>
                <td class="text-right">{{ party.outstanding.today.toFixed(2) }}</td>
                <td class="text-right">{{ party.outstanding.aggregate.toFixed(2) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { dailySalesReport } from "../../services/apiCall.js";
import AlertComponent from "../common/AlertComponent";
import { mdiCalendar } from "@mdi/js";

export default Vue.extend({
  name: "DailySalesReport",

  components: {
    AlertComponent,
  },

  data: () => ({
    icons: {
      mdiCalendar
    },
    reportDate: "",
    reportDateMenu: false,
    alert: {
      error: false,
      success: false,
      message: "",
    },

    salesSummary: {
      orders: {
        value: 0.0, // total sales
        discounts: 0.0, // total discounts
        quantity: 0.0, // total number of items
        count: 0, // total order count
        tax: 0.0, // total tax
        parties: 0, // orders received from how many parties
      },
      cash_flow: {
        received: 0.0, // received amount
        outstanding: {
          today: 0.0,
          aggregate: 0.0,
        }, // outstanding amount
      },
      returns: {
        value: 0.0, // total value of returns
        quantity: 0.0, // total quantity of returns
        count: 0, // total return order count
        parties: 0, // returns received from how many parties
      },
      parties: [
        {
          name: "party1",
          address: "address",
          party_id: 1,
          orders: {
            // (today) orders received by party
            value: 0.0, // total sales
            discounts: 0.0, // total discounts
            quantity: 0.0, // total number of items
            count: 0, // total order count
            tax: 0.0, // total tax
          },
          returns: {
            // (today) returns received by party
            value: 0.0, // total value of returns
            quantity: 0.0, // total item quantity of returns
            count: 0, // total number of return order count
          },
          received: 0.0, // amount received today (today)
          outstanding: {
            today: 0.0,
            aggregate: 0.0,
          }, // amount outstanding
        },
      ],
    },
  }),

  mounted() {
    this.init();
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    init() {
      console.log("DailySalesReport.init");

      this.fetchReport(new Date());
    },

    fetchReport(reportDate) {
      console.log("fetchReport", reportDate);
      
      dailySalesReport({report_date: reportDate})
        .then((res) => {
          console.log("dailySalesReport", res);
          if (res.data.status === 0) {
            this.salesSummary = res.data.result.daily_sales;
          }
        })
        .catch((err) => {
          console.log("dailySalesReport - err", err);
          this.alert.error = true;
          this.alert.success = false;
          this.alert.message = "Unable to connect to server. Error: " + err;
        });
    },
  },
});
</script>