<template>
  <span v-if="userLoggedIn" class="no-padding">
    <v-row class="text-center no-padding" dense>
      <v-col cols="12" dense>
        <v-text-field label="Search cart" v-model="searchField" @keypress.enter="searchItem" @keypress="searchItem"
          hide-details="auto" :append-icon="icons.mdiMagnify" single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-center no-padding" v-if="cart.length > 0" dense>
      <v-col cols="12" class="no-padding" dense>
        <v-data-table dense :headers="headers" :items="cart" :search="searchField" hide-default-footer
          class="no-padding">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="deleteItem(item)">{{ icons.mdiCartMinus }}</v-icon>
            <v-icon small color="error" @click="purgeItem(item)">{{
                icons.mdiTrashCan
            }}</v-icon>
          </template>

          <template v-slot:[`item.remark`]="props">
            <v-edit-dialog @save="save(props.item, editableProperties, 'remark')" @cancel="cancel"
              @open="open(props.item, editableProperties, 'remark')" @close="close">
              {{ props.item.remark }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.remark" type="text" label="Edit"
                  :rules="editablePropertyRules.remark" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.quantity`]="props">
            <v-edit-dialog @save="save(props.item, editableProperties, 'quantity')" @cancel="cancel"
              @open="open(props.item, editableProperties, 'quantity')" @close="close">
              {{ props.item.quantity }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.quantity" type="number" label="Edit"
                  :rules="editablePropertyRules.quantity" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <!-- discount is considered as percentage only -->
          <template v-slot:[`item.discount`]="props">
            <v-edit-dialog @save="save(props.item, editableProperties, 'discount')" @cancel="cancel"
              @open="open(props.item, editableProperties, 'discount')" @close="close">
              {{ props.item.discount }}
              <template v-slot:input>
                <v-text-field v-model="editableProperties.discount" type="number" label="Edit"
                  :rules="editablePropertyRules.discount" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.price`]="props">
            <v-edit-dialog @save="save(props.item, editableProperties, 'price')" @cancel="cancel"
              @open="open(props.item, editableProperties, 'price')" @close="close">
              <span v-if="props.item.discount == 0">
                {{ props.item.price }}
              </span>
              <span v-if="props.item.discount > 0">
                <span style="text-decoration: line-through"><small>{{ props.item.price }}</small></span>
                <br />
                {{
                    (props.item.price -
                      (props.item.price * props.item.discount) / 100.0).toFixed(2)
                }}
              </span>
              <template v-slot:input>
                <v-text-field v-model="editableProperties.price" type="number" label="Edit"
                  :rules="editablePropertyRules.price" single-line required></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="cart.length > 0" class="no-padding" dense>
      <v-col dense>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
        <v-btn raised color="success" @click="generateBillPreview" rounded>
          Save Preview
        </v-btn>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import Vue from "vue";

import { mdiCartMinus, mdiCartRemove, mdiTrashCan, mdiComment, mdiMagnify } from "@mdi/js";

export default Vue.extend({
  name: "CartComponent",

  props: ["generateBillCallback", "callbackObject"],

  mounted() {
    this.init();
  },

  data: () => ({
    searchField: "",
    snack: false,
    snackColor: "",
    snackText: "",
    icons: {
      mdiCartMinus,
      mdiCartRemove,
      mdiTrashCan,
      mdiComment,
      mdiMagnify,
    },
    editableProperties: {
      price: "",
      discount: "",
      quantity: "",
      remark: "",
    },
    editablePropertyRules: {
      price: [],
      discount: [],
      quantity: [],
      remark: [],
    },
    headers: [
      { text: "Name", value: "item_name" },
      { text: "Rate", value: "price" },
      { text: "Dis. (%)", value: "discount" },
      { text: "Qty", value: "quantity" },
      { text: "Remark", value: "remark" },
      { text: "Actions", value: "actions" },
    ],
    rules: {
      required: (v) => !!v || "Required",
      validQty: (v) => /^\d*(\.00|\.50\.0|\.5)?$/.test(v) || "Invalid Qty",
      positiveNumber: (v) => parseFloat(v) >= 0 || "Should not be -ve number",
      validDiscount: (v) => parseFloat(v) >= 0 && parseFloat(v) <= 100,
    },
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    cart() {
      return this.$store.getters.cart;
    },
  },

  methods: {
    init() {
      this.editablePropertyRules.price = [
        this.rules.required,
        this.rules.positiveNumber,
      ];
      this.editablePropertyRules.discount = [
        this.rules.required,
        this.rules.positiveNumber,
        this.rules.validDiscount,
      ];
      this.editablePropertyRules.quantity = [
        this.rules.required,
        this.rules.positiveNumber,
        this.rules.validQty,
      ];
    },
    searchItem() {
      console.log("searchItem", this.searchField);
    },
    deleteItem(item) {
      console.log("deleteItem", item);
      this.$store.dispatch("deleteFromCart", item);
    },
    purgeItem(item) {
      console.log("purgeItem", item);
      this.$store.dispatch("purgeFromCart", item);
    },
    addComment(item) {
      console.log("addComment", item);
    },
    validate(prop, key) {
      var isValid = true;
      var rules = this.editablePropertyRules[key];

      for (var ridx = 0; ridx < rules.length; ridx++) {
        var ruleValidity = rules[ridx](prop[key]);
        // console.log('ruleValidity', key, rules[ridx], ruleValidity);
        if (typeof ruleValidity === "string") ruleValidity = false;
        isValid = isValid && ruleValidity;
      }
      // console.log('validate', key, isValid);
      return isValid;
    },
    save(prop, eprop, key) {
      if (this.validate(eprop, key)) {
        prop[key] = eprop[key];
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Updated [" + key + "]";
      } else {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Validaion failed [" + key + "]";
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open(prop, eprop, key) {
      eprop[key] = prop[key];
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Edit opened [" + key + "]";
    },
    close() {
      console.log("Edit closed");
    },
    sanitizeNumericFields() {
      // sanitize numeric fields, price and discount, convert them to numbers
      console.log("sanitizeNumericFields - call");
      this.cart.forEach((item) => {
        item.price = parseFloat(item.price);
        item.discount = parseFloat(item.discount);
      });
    },
    generateBillPreview() {
      console.log("generateBillPreview - call");
      if (typeof this.generateBillCallback !== "undefined") {
        this.sanitizeNumericFields();
        this.generateBillCallback(this.callbackObject);
      }
    },
  },
});
</script>