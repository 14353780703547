<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <h3>Stock Transfers</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import AlertComponent from "../common/AlertComponent.vue";

export default Vue.extend({
  components: { AlertComponent },
  name: "StockTransferComponent",

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  data: () => ({
    alert: {
      error: false,
      success: false,
      message: "",
    },
  }),

  methods: {
  },
  
});
</script>