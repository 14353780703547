<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-right">
      <v-col cols="12">
        <v-btn v-if="buttonPressed.dailySales" depressed @click="dailySalesReport" rounded
          >Daily Sales</v-btn
        >
        <v-btn v-if="!buttonPressed.dailySales" raised @click="dailySalesReport" rounded
          >Daily Sales</v-btn
        >
        &nbsp; &nbsp;

        <v-btn v-if="buttonPressed.dailyStocks" depressed @click="dailyStocksReport" rounded
          >Daily Stocks</v-btn
        >
        <v-btn v-if="!buttonPressed.dailyStocks" raised @click="dailyStocksReport" rounded
          >Daily Stocks</v-btn
        >
        &nbsp; &nbsp;

        <v-btn v-if="buttonPressed.partySales" depressed @click="partySalesReport" rounded
          >Party Wise Sales</v-btn
        >
        <v-btn v-if="!buttonPressed.partySales" raised @click="partySalesReport" rounded
          >Party Wise Sales</v-btn
        >
        &nbsp; &nbsp;
        
        <v-btn v-if="buttonPressed.supplierStocks" depressed @click="supplierStocksReport" rounded
          >Supplier Stocks</v-btn
        >
        <v-btn v-if="!buttonPressed.supplierStocks" raised @click="supplierStocksReport" rounded
          >Supplier Stocks</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-center no-padding">
        <v-col cols="12" class="no-padding">
            <daily-sales-report v-if="buttonPressed.dailySales"></daily-sales-report >
            <daily-item-stock-report v-if="buttonPressed.dailyStocks"></daily-item-stock-report >
            <party-sales-report v-if="buttonPressed.partySales"></party-sales-report>
            <supplier-stock-report v-if="buttonPressed.supplierStocks"></supplier-stock-report>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import DailySalesReport from "../reports/DailySalesReport.vue";
import DailyItemStockReport from "../reports/DailyItemStockReport.vue";
import SupplierStockReport from "../reports/SupplierStockReport.vue";
import PartySalesReport from "../reports/PartySalesReport.vue";

export default Vue.extend({
  name: "ReportsComponent",

  components: {
    DailySalesReport,
    DailyItemStockReport,
    SupplierStockReport,
    PartySalesReport,
  },

  data: () => ({
    buttonPressed: {
      dailySales: false,
      dailyStocks: false,
      partySales: false,
      supplierStocks: false,
    }
  }),
  
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {
    resentButtonPressed(theKey) {
      for (var key in this.buttonPressed) {
        if (key == theKey) {
          this.buttonPressed[key] = !this.buttonPressed[key];
        } else {
          this.buttonPressed[key] = false;
        }
      }
    },

    dailySalesReport() {
      this.resentButtonPressed("dailySales");
    },
    dailyStocksReport() {
      this.resentButtonPressed("dailyStocks");
    },
    supplierStocksReport() {
      this.resentButtonPressed("supplierStocks");
    },
    partySalesReport() {
      this.resentButtonPressed("partySales");
    }
  },
});
</script>