<template>
    <v-container v-if="userLoggedIn">
        <v-row v-if="alert.error || alert.success">
            <v-col cols="12">
                <alert-component :alert="alert"></alert-component>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col cols="12"> Partywise Sales Report </v-col>
        </v-row>
        <v-row class="text-center no-padding">
            <v-col cols="12" class="no-padding">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <td colspan="2"><b>Party</b></td>
                                <td colspan="5"><b>Bills</b></td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>City</td>
                                <td>Bill No.</td>
                                <td>Amount</td>
                                <td>Discount</td>
                                <td>Tax</td>
                                <td>Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="party_record in partySalesSummary">
                                <template v-for="sales_record in party_record">
                                    <tr :key="sales_record.id">
                                        <td>{{ sales_record.party_name }}</td>
                                        <td>{{ sales_record.party_city }}</td>
                                        <td>{{ sales_record.order_id }}</td>
                                        <td>{{ sales_record.order_value }}</td>
                                        <td>{{ sales_record.total_discount }}</td>
                                        <td>{{ sales_record.total_tax }}</td>
                                        <td>{{ (new Date(sales_record.createdAt)).toLocaleString() }}</td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import Vue from "vue";

import { partyWiseSalesReport } from "../../services/apiCall.js";

export default Vue.extend({
    name: "PartySalesReport",

    data: () => ({
        alert: {
            error: false,
            success: false,
            message: "",
        },
        partySalesSummary: {},
    }),

    mounted() {
        this.init();
    },

    computed: {
        userLoggedIn() {
            return this.$store.getters.userLoggedIn;
        },
    },

    methods: {
        init() {
            console.log('PartySalesReport.init');

            this.fetchReport(new Date());
        },

        fetchReport(reportDate) {
            partyWiseSalesReport({ report_date: reportDate })
                .then((res) => {
                    console.log("partyWiseSalesReport", res);
                    if (res.data.status == 0) {
                        this.partySalesSummary = res.data.result.party_wise_sales;
                    }
                })
                .catch((err) => {
                    console.log("dailySalesReport - err", err);
                    this.alert.error = true;
                    this.alert.success = false;
                    this.alert.message = "Unable to connect to server. Error: " + err;
                });
        },
    },
});
</script>