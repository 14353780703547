<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        Collection
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "CollectionComponent",

  data: () => ({}),
  
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {},
});
</script>