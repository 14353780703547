<template>
  <v-container v-if="userLoggedIn" class="px-0" fluid>
    <v-row v-if="alert.error || alert.success">
      <v-col cols="12">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>
    <v-row class="text-center no-padding">
      <v-col cols="12"> Search Bills </v-col>
      <v-col cols="4">
        <v-menu ref="dateRangeMenu" v-model="dateRangeMenu" :close-on-content-click="false" transition="scale-transition"
          offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateRange" label="Date Range" persistent-hint :prepend-icon="icons.mdiCalendar"
              v-bind="attrs" v-on="on" readonly></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" no-title @input="dateRangeMenu = false" range></v-date-picker>
        </v-menu>

        <v-btn @click="searchBillsInRange">Search Bills in Range</v-btn>
      </v-col>
      <v-col cols="8" class="no-padding" style="text-align: left;">
        <v-autocomplete v-model="gstOrderNumber" :items="gstOrderNumberList" label="Select Order Number" hide-no-data
          hide-details required @change="orderChanged"></v-autocomplete>

        <span>Found <b>{{ gstOrderNumberList.length }}</b> orders.</span>
      </v-col>
    </v-row>
    <v-row class="no-padding">
      <v-col cols="12" class="no-padding">
        <bill-preview-component :billSettings="billSettings" :orderID="gstOrderNumber" :printOnly=true
          v-if="gstOrderNumber != ''" :billDate="billDetails.createdAt"
          :billDetails="billDetails"></bill-preview-component>
      </v-col>
      <v-col cols="12" style="text-align: right" v-if="gstOrderNumber != ''">
        <v-btn raised color="error" @click="cancelBill" v-if="billDetails.order_status != 'Cancelled'" rounded>
          Cancel Bill
        </v-btn>
        &nbsp; &nbsp;
        <v-btn raised color="error" @click="cloneCancelBill" v-if="billDetails.order_status != 'Cancelled'" rounded>
          Clone & Cancel Bill
        </v-btn>
        <span v-if="billDetails.order_status == 'Cancelled'" style="color: red;">
          <b>Order is marked Cancelled</b>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import { mdiCalendar } from "@mdi/js";

import { listGSTOrderIDs, fetchGSTOrder, cancelGSTOrder } from "../../services/apiCall.js";
import AlertComponent from "../common/AlertComponent";
import BillPreviewComponent from "./BillPreviewComponent.vue";

export default Vue.extend({
  name: "BillSearchComponent",

  components: {
    AlertComponent,
    BillPreviewComponent,
  },

  data: () => ({
    icons: {
      mdiCalendar
    },
    dateRange: "",
    dateRangeMenu: false,
    gstOrderNumber: "",
    gstOrderNumberList: [],
    alert: {
      error: false,
      success: false,
      message: "",
    },
    billSettings: {
      counterRateType: true,
      salesBillOrderType: "GST Bill", // default is GST bill order
      sameStateCustomer: true,
      billPreviewEnabled: false,
    },
    billDetails: {},
  }),

  mounted() {
    this.init();
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    init() {
      this.dateRange = [];

      this.searchBillsInRange();
    },
    acceptReturn() {
      console.log("acceptReturn");
    },
    orderChanged(order_id) {
      console.log("orderChanged", order_id);

      this.billSettings = {
        counterRateType: true,
        salesBillOrderType: "GST Bill", // default is GST bill order
        sameStateCustomer: true,
        billPreviewEnabled: false,
      };

      // 1. Fetch Bill Details
      fetchGSTOrder({ order_id: order_id }).then((gst_order) => {
        console.log("fetchGSTOrder", gst_order);
        if (gst_order.data.status != 0) {
          this.alert.error = true;
          this.alert.message = gst_order.data.error.error_message;
          return;
        }

        // 2. Get bill details and update billSettings 
        gst_order = gst_order.data.result.gst_order;

        this.billSettings.sameStateCustomer = gst_order.same_state_billing;

        this.billDetails = gst_order;
        this.gstOrderNumber = gst_order.order_id;

        console.log("order-status", this.billDetails.order_status);

        this.$store.dispatch("setCart", gst_order["items"]);
        this.$store.dispatch("setBillingCustomer", gst_order["party"]);
      });
    },
    clearAlerts() {
      this.alert.success = this.alert.error = false;
      this.alert.message = "";
    },
    cancelBill() {
      console.log("cancelBill");

      this.clearAlerts();

      cancelGSTOrder({
        order_id: this.gstOrderNumber
      }).then((res) => {
        console.log('cancelGSTOrder - res', res);
        if (res.data.status == 0) {
          this.alert.success = true;
          this.alert.message = "Order marked as cancelled";
        } else {
          this.alert.error = true;
          this.alert.message = "Unable to cancel order. Error: " + res.data.error.error_message;
        }
      }).catch((err) => {
        console.log('cancelGSTOrder - err', err);
        this.alert.error = true;
        this.alert.message = "Error cancelling the order. Error: " + err;
      });
    },
    cloneCancelBill() {
      console.log("cloneCancelBill");

      this.clearAlerts();

      cancelGSTOrder({
        order_id: this.gstOrderNumber
      }).then((res) => {
        console.log('cancelGSTOrder - res', res);
        if (res.data.status == 0) {
          this.alert.success = true;
          this.alert.message = "Order marked as cancelled";
        } else {
          this.alert.error = true;
          this.alert.message = "Unable to cancel order. Error: " + res.data.error.error_message;
        }
      }).catch((err) => {
        console.log('cancelGSTOrder - err', err);
        this.alert.error = true;
        this.alert.message = "Error cancelling the order. Error: " + err;
      });
    },
    searchBillsInRange() {
      console.log("searchBillsInRange", this.dateRange);
      let startDate = new Date();
      let endDate = new Date();
      if (this.dateRange.length == 1) {
        startDate = endDate = this.dateRange[0];
      } else if (this.dateRange.length == 2) {
        startDate = this.dateRange[0];
        endDate = this.dateRange[1];
      }

      listGSTOrderIDs({ start_date: startDate, end_date: endDate })
        .then((res) => {
          console.log("listGSTOrder", res);

          if (res.data.status === 0) {
            this.gstOrderNumberList = res.data.result.order_list.map(
              (x) => x.order_id
            );
          } else {
            console.log("listGSTOrder - err1", res);
          }
        })
        .catch((err) => {
          console.log("listGSTOrder - err2", err);
        });
    },
  },
});
</script>
