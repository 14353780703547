<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <v-tabs v-model="selectedTab" grow left>
          <v-tab key="billing">Billing</v-tab>
          <v-tab key="stocks">Stocks</v-tab>
          <v-tab key="reports">Reports</v-tab>
          <v-tab key="ledger">Ledger</v-tab>
          <v-tab key="collection">Collection</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item key="billing"><billing-component></billing-component></v-tab-item>
          <v-tab-item key="stocks"><stocks-component></stocks-component></v-tab-item>
          <v-tab-item key="reports"><reports-component></reports-component></v-tab-item>
          <v-tab-item key="ledger"><ledger-component></ledger-component></v-tab-item>
          <v-tab-item key="collection"><collection-component></collection-component></v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import BillingComponent from "./BillingComponent.vue";
import StocksComponent from "./StocksComponent.vue";
import ReportsComponent from "./ReportsComponent.vue";
import CollectionComponent from "./CollectionComponent.vue";
import LedgerComponent from "./LedgerComponent.vue";

export default Vue.extend({
  name: "DashboardComponent",

  components: {
    BillingComponent,
    StocksComponent,
    ReportsComponent,
    CollectionComponent,
    LedgerComponent
  },

  data: () => ({
    selectedTab: "Billing"
  }),
  
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {},
});
</script>