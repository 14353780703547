<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-center">
      <v-col cols="12">
        <h3>Optical Lens Sheet</h3>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col>
                <v-combobox :items="product_companies" v-model="selected_company" label="Company"></v-combobox>
              </v-col>
              <v-col>
                <v-combobox :items="product_types" v-model="selected_type" label="Product Type"></v-combobox>
              </v-col>
              <v-col>
                <v-combobox :items="product_attributes" v-model="selected_attribute"
                  label="Product Attribute"></v-combobox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-btn @click="fetchProductDetails" rounded>Fetch Details</v-btn>
          </v-col>
        </v-row>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td></td>
                <td v-for="col_header in headers.cols" v-bind:key="col_header"><b>{{ formatRange(col_header) }}</b></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row_header in headers.rows" v-bind:key="row_header">
                <td>
                  <b>{{ formatRange(row_header) }}</b>
                </td>
                <td v-for="col_header in headers.cols" v-bind:key="col_header" class="pa-2 ma-2">
                  <v-text-field :value="fetchValue(col_header, row_header)" style="font-size: 10px;" class="pa-0 ma-0 text-center" variant="solo" dense></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";

import {
  types,
  companies,
  itemSpecificList,
} from "../../services/apiCall.js";

export default Vue.extend({
  name: "OpticalSheetComponent",

  mounted() {
    this.init();
  },

  data: () => ({
    range: {
      spherical: {
        min: 0.0,
        max: 10.0,
        step: 0.25
      },
      cylinder: {
        min: 0.0,
        max: 10.0,
        step: 0.25
      }
    },

    headers: {
      cols: [],
      rows: []
    },

    product_companies: [],
    product_types: [],
    product_attributes: [
      "Stock Count",
      "Counter Price",
      "Dispatch Price",
      "Short Code",
      "Bar Code",
      "Min. Order. Qty."
    ],
    product_attribute_map: {
      "Stock Count": "quantity",
      "Min. Order. Qty.": "min_quantity",
      "Counter Price": "counter_price",
      "Dispatch Price": "dispatch_price",
      "Short Code": "short_code",
      "Bar Code": "bar_code"
    },
    product_list: [],
    
    selected_company: "",
    selected_type: "",
    selected_attribute: ""
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {
    init() {
      console.log("OpticalSheetComponent.init called");

      this.headers.cols = [];
      this.headers.rows = [];
      for (let cnt = this.range.spherical.min; cnt <= this.range.spherical.max; cnt += this.range.spherical.step) {
        this.headers.rows.push(cnt + "");
      }
      for (let cnt = this.range.cylinder.min; cnt <= this.range.cylinder.max; cnt += this.range.cylinder.step) {
        this.headers.cols.push(cnt + "");
      }
      console.log(this.headers)

      try {
        types({})
          .then((res) => {
            // console.log("types", res, this);
            if (res.data.status === 0) {
              this.product_types = res.data.result.type_list;
            }
          })
          .catch((err) => {
            console.log("types - err", err);
          });
      } catch (err) {
        console.log("types - err", err);
      }

      try {
        companies({})
          .then((res) => {
            // console.log("companies", res, this);
            if (res.data.status === 0) {
              this.product_companies = res.data.result.company_list;
            }
          })
          .catch((err) => {
            console.log("companies - err", err);
          });
      } catch (err) {
        console.log("companies - err", err);
      }
    },

    formatRange(range) {
      return parseFloat(range).toFixed(2).padStart(3, '0').replace('.', '')
    },

    fetchValue(col_header, row_header) {
      let item = this.product_list.filter((x) => { 
        let cond  = parseFloat(x.attributes.cyl) == parseFloat(col_header) && parseFloat(x.attributes.sph) == parseFloat(row_header);
        return cond
      })

      if (item.length > 0) {
        return item[0][this.product_attribute_map[this.selected_attribute]]
      }

      return 0.0
    },

    fetchProductDetails() {
      try {
        itemSpecificList({
          product_type: this.selected_type,
          product_company: this.selected_company,
          product_attribute: this.product_attribute_map[this.selected_attribute]
        })
          .then((res) => {
            console.log("item-specific-list", res);

            if (res.data.status == 0) {
              this.product_list = res.data.result.item_list
            }
          })
      } catch(err) {
        console.log("")
      }
    }
  }
})
</script>