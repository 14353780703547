<template>
  <v-container v-if="userLoggedIn">
    <v-row v-if="alert.error || alert.success" class="no-padding">
      <v-col cols="12" class="no-padding">
        <alert-component :alert="alert"></alert-component>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col cols="12">
        <h3>Ledger</h3>
      </v-col>
      <!-- party name search -->
      <v-row class="text-center no-padding">
        <v-col cols="10" class="no-padding text-left">
          <v-autocomplete :items="partyNameList" v-model="customer" :rules="[rules.required]" label="Customer"
            @change="partyChanged" required class="no-padding">
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-btn @click="fetchLedger" rounded>Show Ledger</v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table dense :headers="headers" :items="ledgerEntries" :items-per-page="5" class="elevation-1" clearable>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import Vue from "vue";

import AlertComponent from "../common/AlertComponent";

import {
  externalParties,
  fetchPartyLedger,
} from "../../services/apiCall.js";

export default Vue.extend({
  name: "LedgerComponent",

  components: {
    AlertComponent,
  },

  data: () => ({
    externalPartyList: [],
    partyNameList: [],
    externalPartyMap: {},
    customer: {},
    rules: {
      required: (v) => !!v || "Required",
    },
    alert: {
      error: false,
      success: false,
      message: "",
    },
    ledgerEntries: [],
    headers: [
      { text: "Sr. No.", value: "idx" },
      { text: "Date", value: "createdAt" },
      { text: "Amount", value: "entry_value" },
      { text: "Type", value: "entry_type" },
      { text: "Mode", value: "payment_mode" },
      { text: "Balance", value: "current_value" },
      { text: "Remark / Bill No.", value: "remark" },
    ]
  }),

  mounted() {
    this.init();
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    }
  },

  methods: {
    init() {
      externalParties({ party_type: "Customer" })
        .then((res) => {
          console.log("externalParties", res);

          if (res.data.status === 0) {
            this.externalPartyList = res.data.result.external_party_list;
            this.partyNameList = this.externalPartyList.map((party) => {
              let partyCode =
                party.party_name.toUpperCase() +
                "|" +
                party.party_city.toUpperCase() +
                "|" +
                party.party_address.toUpperCase() +
                "|" +
                party.id;

              this.externalPartyMap[partyCode] = party;

              return partyCode;
            });
          } else {
            console.log("externalParties - err1", res);
          }
        })
        .catch((err) => {
          console.log("externalParties - err2", err);
        });
    },

    fetchLedger() {
      console.log('fetchLedger', this.customer, this.externalPartyMap[this.customer])
      fetchPartyLedger({
        party_id: this.externalPartyMap[this.customer].id,        
      }).then((res) => {
        console.log('fetchLedger', res);
        if (res.data.status === 0) {
          this.ledgerEntries = res.data.result.ledger?.entries;
          let idx = 1;
          this.ledgerEntries = this.ledgerEntries.map((entry) => {
            entry.createdAt = new Date(entry.createdAt).toLocaleDateString();
            entry.entry_value = entry.entry_value.toFixed(2);
            entry.current_value = entry.current_value.toFixed(2);
            entry.idx = idx++;
            return entry
          })
        } else {
          console.log('fetchLedger - err1', res);
        }
      }).catch((err) => {
        console.log('fetchLedger - err', err);
      })
    },

    partyChanged() {
      console.log(
        "partyChanged",
        this.customer,
        this.externalPartyMap[this.customer]
      );
    }
  },
});
</script>