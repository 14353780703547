<template>
  <v-container v-if="userLoggedIn">
    <v-row class="text-right" dense>
      <v-col cols="12" dense>
        <v-btn v-if="buttonPressed.newBill" depressed @click="newBill" rounded
          >New Bill</v-btn
        >
        <v-btn v-if="!buttonPressed.newBill" raised @click="newBill" rounded
          >New Bill</v-btn
        >
        &nbsp;&nbsp;
        
        <v-btn v-if="buttonPressed.searchBill" depressed @click="searchBill" rounded
          >Search Bill</v-btn
        >
        <v-btn v-if="!buttonPressed.searchBill" raised @click="searchBill" rounded
          >Search Bill</v-btn
        >
        &nbsp;&nbsp;
        
        <v-btn v-if="buttonPressed.returnBill" depressed @click="returnBill" rounded
          >Returns</v-btn
        >
        <v-btn v-if="!buttonPressed.returnBill" raised @click="returnBill" rounded
          >Returns</v-btn
        >
        &nbsp;&nbsp;
        
        <v-btn v-if="buttonPressed.addParty" depressed @click="addParty" rounded
          >Manage Party</v-btn
        >
        <v-btn v-if="!buttonPressed.addParty" raised @click="addParty" rounded
          >Manage Party</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="text-center no-padding" dense>
        <v-col cols="12" class="no-padding" dense>
            <new-bill-component v-if="buttonPressed.newBill"></new-bill-component>
            <add-party-component v-if="buttonPressed.addParty"></add-party-component>
            <return-bill-component v-if="buttonPressed.returnBill"></return-bill-component>
            <bill-search-component v-if="buttonPressed.searchBill"></bill-search-component>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import NewBillComponent from "../billing/NewBillComponent.vue";
import AddPartyComponent from "../billing/AddPartyComponent.vue";
import ReturnBillComponent from "../billing/ReturnBillComponent.vue";
import BillSearchComponent from "../billing/BillSearchComponent.vue";

export default Vue.extend({
  name: "BillingComponent",

  components: {
    NewBillComponent,
    AddPartyComponent,
    ReturnBillComponent,
    BillSearchComponent
  },

  data: () => ({
    buttonPressed: {
      newBill: true,
      returnBill: false,
      addParty: false,
      searchBill: false,
    },
  }),

  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },

  methods: {
    resentButtonPressed(theKey) {
      for (var key in this.buttonPressed) {
        if (key == theKey) {
          this.buttonPressed[key] = !this.buttonPressed[key];
        } else {
          this.buttonPressed[key] = false;
        }
      }
    },
    newBill() {
      console.log("newBill");
      this.$store.dispatch("setCart", []);
      this.$store.dispatch("setBillingCustomer", {});
      this.resentButtonPressed("newBill");
    },
    searchBill() {
      console.log("searchBill");
      this.$store.dispatch("setCart", []);
      this.$store.dispatch("setBillingCustomer", {});
      this.resentButtonPressed("searchBill");
    },
    addParty() {
      console.log("addParty");
      this.resentButtonPressed("addParty");
    },
    returnBill() {
      console.log("returnBill");
      this.resentButtonPressed("returnBill");
    },
  },
});
</script>